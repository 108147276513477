/* Theme Bg Color  */

.rn-gradient-circle {
  position: fixed;
  left: -250px;
  top: 250px;
  right: auto;
  bottom: auto;
  z-index: -1;
  width: 500px;
  height: 500px;
  border-radius: 1000px;
  background-image: linear-gradient(
    45deg,
    var(--color-primary),
    var(--color-light-green)
  );
  opacity: 0.25;
  -webkit-filter: blur(100px);
  filter: blur(100px);

  &.theme-pink {
    left: auto;
    top: -250px;
    right: -250px;
    bottom: auto;
    background-image: linear-gradient(
      45deg,
      var(--color-secondary),
      var(--color-tertiary)
    );
  }
}

.theme-shape {
  position: relative;
  z-index: 2;
  overflow: hidden;

  &::before {
    position: absolute;
    left: -250px;
    top: 250px;
    right: auto;
    bottom: auto;
    z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    background-image: linear-gradient(
      45deg,
      var(--color-primary),
      var(--color-light-green)
    );
    opacity: 0.2;
    filter: blur(100px);
    content: "";
  }

  &::after {
    position: absolute;
    z-index: -1;
    width: 500px;
    height: 500px;
    border-radius: 1000px;
    opacity: 0.2;
    filter: blur(100px);
    content: "";
    left: auto;
    top: -250px;
    right: -250px;
    bottom: auto;
    background-image: linear-gradient(
      45deg,
      var(--color-secondary),
      var(--color-tertiary)
    );
  }
}

.link-hover {
  a {
    display: inline-block;
    position: relative;
    &::after {
      position: absolute;
      content: "";
      left: auto;
      bottom: 0;
      background: currentColor;
      width: 0;
      height: 2px;
      transition: 0.3s;
      right: 0;
    }
    &:hover {
      &::after {
        width: 100%;
        left: 0;
        right: auto;
      }
    }
  }
}
